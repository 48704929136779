import { snowplow } from '@tourlane/tracking';

import { TrackingSchemas } from '@shared/utils/tracking';

// defines how many seconds after pageload a pageping event will fire
const START_PAGEPING_EVENTS = 5;

export const setUpSnowplowTracking = () => {
  window.snowplow('newTracker', 'sp', process.env.GATSBY_SNOWPLOW_COLLECTOR_ENDPOINT, {
    appId: 'phoenix',
    discoverRootDomain: true,
    post: true,
    contexts: {
      webPage: true,
      performanceTiming: true,
      gaCookies: true,
    },
  });

  snowplow.setup({ featureFlags: {}, entities: [] }, START_PAGEPING_EVENTS);
};

export const setUpGTM = () => {
  window.addEventListener('load', function () {
    (function (window, document, dataLayer, id) {
      window[dataLayer] = window[dataLayer] || [];
      window[dataLayer].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const dl = dataLayer != 'dataLayer' ? '&l=' + dataLayer : '';

      const scriptEl: HTMLScriptElement = document.createElement('script');
      scriptEl.async = true;
      scriptEl.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl;

      document.body.appendChild(scriptEl);
    })(window, document, 'dataLayer', 'GTM-PJLN23P');
  });
};

export const trackPage = ({ pageContext, location }) => {
  const { destinationUuid = '', type = '' } = pageContext;
  const params = new URLSearchParams(location?.search);
  let urlParams = {};

  params.forEach((value, key) => {
    urlParams[key] = value;
  });

  snowplow.trackPageView({
    destination: destinationUuid,
    pageType: type,
    entities: [
      {
        schema: TrackingSchemas.ANYTHING_A_ENTITY,
        data: {
          urlParams,
        },
      },
    ],
  });
};
